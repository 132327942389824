// noinspection JSCheckFunctionSignatures

(function() {

  /**
   * Replaces all checkbox inputs with custom elements.
   *
   * @returns {object} Returns the jQuery object.
   */
  $.fn.esoCheckbox = function() {

    let nCheckboxes = 1;
    let checkedState = false;

    function checkboxHandler() {

      let obj = $(this);
      let srcID = obj.attr('id').slice(0, -4);
      let srcObj = $('#' + srcID);

      if(srcObj.is(':checked') === false) {
        obj.children().addClass('fa-check');
        srcObj.prop('checked', true);
      }
      else {
        obj.children().removeClass('fa-check');
        srcObj.prop('checked', false);
      }
    }

    return this.each(function() {

      let obj = $(this);

      checkedState = false;

      // Only add custom elements when data attribute is not present
      if(obj.data('ct') !== '1') {

        let id = obj.attr('id');

        // Generate Id
        if(id === undefined || id === '') {
          obj.attr('id', 'cb' + nCheckboxes);
          id = obj.attr('id');
        }

        if(obj.is(':checked') === true) {
          checkedState = true;
        }

        obj.after('<span id="' + id + 'View" class="checkbox"><span class="fa' + ((checkedState === true) ? ' fa-check' : '') + '"></span></span>');

        $('#' + id + 'View').on('click', checkboxHandler);

        obj.hide();
        obj.data('ct', 1);

        nCheckboxes++;
      }
    });
  };

  /**
   * Replaces all radio inputs with custom elements.
   *
   * @returns {object} Returns the jQuery object.
   */
  $.fn.esoRadio = function() {

    let nRadios = 1;
    let radioState = false;

    function radioHandler() {

      let obj = $(this);
      let srcID = obj.attr('id').slice(0, -4);
      let srcObj = $('#' + srcID);
      let radioGroupName = srcObj.attr('name');

      // Disable all radio buttons of the group
      $('input[name="' + radioGroupName + '"]').each(function(key, value) {
        $('#' + $(value).attr('id') + 'View').children().remove();
        $(value).prop('checked', false);
      });

      // Activate radio button
      obj.append('<span></span>');
      srcObj.prop('checked', true);
    }

    return this.each(function() {

      let obj = $(this);

      radioState = false;

      // Only add custom elements when data attribute is not present
      if(obj.data('rt') !== '1' && obj.attr('name') !== 'email_repeat_2') {

        let id = obj.attr('id');

        // Generate Id
        if(id === undefined || id === '') {
          obj.attr('id', 'ra' + nRadios);
          id = obj.attr('id');
        }

        // Prüfen ob Radio aktiv ist
        if(obj.is(':checked') === true) {
          radioState = true;
        }

        obj.after('<span id="' + id + 'View" class="radio">' + ((radioState === true) ? '<span></span>' : '') + '</span>');

        $('#' + id + 'View').on('click', radioHandler);

        obj.hide();
        obj.data('rt', 1);

        nRadios++;
      }
    });
  };

  /**
   * Adds the given data attribute.
   *
   * @param {string} key The data attribute key.
   * @param {string|number} value The data value to set.
   * @returns {object} Returns the jQuery object.
   */
  $.fn.addData = function(key, value) {
    this.attr('data-' + key, value).data(key, value);
    return this;
  };

  /**
   * Removes the given data attribute.
   *
   * @param {string} key The data attribute key.
   * @returns {object} Returns the jQuery object.
   */
  $.fn.deleteData = function(key) {
    this.removeAttr('data-' + key).removeData(key);
    return this;
  };

  /**
   * Adds the given class name only if the class name is not already present.
   *
   * @param {string} className The class name.
   * @returns {object} Returns the jQuery object.
   */
  $.fn.addClassOnce = function(className) {
    if(this.hasClass(className) === false) {
      this.addClass(className);
    }
    return this;
  };

})();
